/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// app styles
import './src/styles/styles.scss';

import React from 'react';
import { ContextProvider } from './src/context/storeContext';
import Layout from './src/components/layout';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Cookies } from 'react-cookie-consent';
import { isDev } from './src/utils/environment';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// polyfills
import 'isomorphic-fetch';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.GATSBY_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    isCookieUseDisabled: !Cookies.get('cookie-consent')
  }
});

const initStamped = () => {
  window.StampedFn.init({ apiKey: process.env.GATSBY_STAMPED_API_KEY, storeUrl: process.env.GATSBY_SHOPIFY_STORE_URL });
};

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
);

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
};

export const onInitialClientRender = () => {
  AOS.init();

  if (window.StampedFn && window.jQuery) {
    initStamped();
  }

  // load AppInsights and manually call trackPageView to establish the current user/session/pageview
  if (!isDev) {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if ((window.StampedFn && window.jQuery) && (location.pathname === '/' || location.pathname.includes('product'))) {
    initStamped();
  }

  if (!isDev) {
    appInsights.trackPageView();
  }
};

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}