import React from 'react';
import { Link } from 'gatsby';
import Icon from '../icon/icon';
import * as styles from './footer.module.scss';

interface Props {
  siteTitle: string;
}

const Footer = ({ siteTitle }: Props) => {
  return (
    <footer className={`${styles.footer} container-fluid`}>
      <div className={`${styles.content} centered`}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>Információ</h4>
              <ul className={styles.footerLinks}>
                <li>
                  <Link to="/legal/terms-of-service">Általános Szerződési Feltételek</Link>
                </li>
                <li>
                  <Link to="/legal/privacy-policy">Adatkezelési tájékoztató</Link>
                </li>
                <li>
                  <Link to="/legal/cookie-policy">Süti szabályzat</Link>
                </li>
                <li>
                  <Link to="/legal/imprint">Impresszum</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>{siteTitle}</h4>
              <ul className={styles.footerLinks}>
                <li className="separator">
                  <Link to="/contact">Kapcsolat</Link>
                </li>
                <li>
                  <Link to="/about">Rólunk</Link>
                </li>
                <li>
                  <Link to="/faq">Gyakori kérdések</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.social}>
          {/* Use default <a> tags because these are not Gatsby links */}
          <a href="https://www.instagram.com/pixwrist" title="Instagram" target="_blank" rel="noopener" aria-label="Instagram">
            <Icon name="instagram" size={20} />
          </a>
          <a className="tiktok" href="https://www.tiktok.com/@pixwrist" title="TikTok" target="_blank" rel="noopener" aria-label="TikTok">
            <svg viewBox="-32 0 512 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M432.734 112.465c-53.742 0-97.464-43.723-97.464-97.465 0-8.285-6.72-15-15-15h-80.336c-8.286 0-15 6.715-15 15v329.367c0 31.594-25.704 57.297-57.301 57.297-31.594 0-57.297-25.703-57.297-57.297 0-31.597 25.703-57.3 57.297-57.3 8.285 0 15-6.715 15-15V191.73c0-8.28-6.715-15-15-15C75.199 176.73 0 251.934 0 344.367 0 436.801 75.2 512 167.633 512S335.27 436.8 335.27 344.367V198.574c29.855 15.918 63.074 24.227 97.464 24.227 8.286 0 15-6.715 15-15v-80.336c0-8.281-6.714-15-15-15zm-15 79.715c-32.023-2.664-62.433-13.852-88.707-32.75a14.987 14.987 0 0 0-15.601-1.172 14.99 14.99 0 0 0-8.156 13.347v172.762c0 75.89-61.747 137.633-137.637 137.633S30 420.258 30 344.367c0-70.824 53.773-129.328 122.633-136.824v50.812c-41.016 7.133-72.297 42.985-72.297 86.012 0 48.137 39.16 87.301 87.297 87.301 48.14 0 87.3-39.164 87.3-87.3V30h51.212c6.87 58.32 53.27 104.719 111.59 111.59zm0 0" fill="currentColor"/>
            </svg>
          </a>
          <a href="https://www.facebook.com/pixwrist" title="Facebook" target="_blank" rel="noopener" aria-label="Facebook">
            <Icon name="facebook" size={20} />
          </a>
          <a href="mailto:pixwrist@pixwrist.hu" title="E-mail" aria-label="E-mail">
            <Icon name="mail" size={20} />
          </a>
        </div>
        <div className={styles.logoWrapper}>
          <Link to="/" className={styles.logo}>
            <img src="/images/logo.svg" width="68" height="64" alt="Logo" />
          </Link>
        </div>
        <div className={styles.copyright}>© {new Date().getFullYear()} {siteTitle}</div>
      </div>
    </footer>
  );
};

export default Footer;