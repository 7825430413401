import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Context } from '../../context/storeContext';
import { formatPrice } from '../../utils/format';
import Badge from '../badge/badge';
import Icon from '../icon/icon';
import * as styles from './cartIndicator.module.scss';

const CartIndicator = () => {
  const {
    state: {
      checkout: { lineItems },
      loading,
      lastItemAdded
    },
    resetLastItemAdded
  } = useContext(Context);

  const [showMessage, setShowMessage] = useState(false);
  const total = lineItems.filter(item => !!item.variant).reduce((acc, item) => acc + item.quantity, 0);

  useEffect(() => {
    if (lastItemAdded) {
      setShowMessage(true);

      const timeout = setTimeout(() => {
        setShowMessage(false);
        resetLastItemAdded();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [lastItemAdded]);

  return (
    <div className={styles.cartIndicator}>
      <Link to="/cart" className={styles.cartIndicatorLink} activeClassName="active" partiallyActive={true} aria-label="Kosár">
        <Icon className={styles.icon} name="shopping-bag" size={24} />
        <span className={styles.cartIndicatorText}>Kosár</span>
        <Badge className={`${styles.cartIndicatorCount} ${loading ? 'invisible' : ''}`}>{total}</Badge>
      </Link>
      {showMessage && lastItemAdded &&
        <Link className={styles.lastItemAdded} to="/cart">
          <div className={styles.lastItemAddedSuccess}>
            <Icon className={styles.notificationIcon} name="check" strokeWidth={2} />
            Termék a kosárba helyezve
          </div>
          <GatsbyImage
            image={lastItemAdded.product.media[0].preview.image.gatsbyImageData}
            className={styles.productImage}
            backgroundColor="#FFF"
            alt={`${lastItemAdded.product.title} karkötő`} />
          <div className={styles.productInfo}>
            <div className={styles.productTitle}>{lastItemAdded.product.title}</div>
            <div className={styles.productVariantTitle}>Méret: {lastItemAdded.variant.title}</div>
            <div className={styles.productPrice}>{formatPrice(lastItemAdded.variant.price)}</div>
          </div>
        </Link>
      }
    </div>
  );
}

export default CartIndicator;