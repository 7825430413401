import { isPlatformBrowser } from './platform';

export const getQueryParams = () => {
  return isPlatformBrowser && window.URLSearchParams ? new URLSearchParams(window.location.search) : null;
};

export const setQueryParams = (key: string, value: string) => {
  if (isPlatformBrowser && window.URLSearchParams) {
    const queryParams = getQueryParams();
    queryParams.set(key, value);

    const baseUrl = window.location.href.split('?')[0];
    const url = `${baseUrl}?${queryParams.toString()}`;

    // replace history state without page refresh
    window.history.replaceState({}, document.title, url);
  }
};