import React, { useState, useEffect } from 'react';
import { isIE } from '../../utils/browser';
import Icon from '../icon/icon';
import * as styles from './unsupportedBrowser.module.scss';

const UnsupportedBrowser = props => {
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    // force re-render component, because of re-hydration issue (client rendered content differs from server rendered) (https://github.com/gatsbyjs/gatsby/issues/14601)
    setVisibility(isIE);
  }, []);

  return (
    <>
      {visible &&
        <div className={styles.unsupportedBrowser}>
          <div className="centered">
            <div>
              <Icon className={styles.icon} name="alert-circle" size={24} aria-hidden="true" />
              <span>
                Sajnáljuk, de az <strong>Internet Explorer</strong> böngészőt már nem támogatjuk,
                így nem tudjuk garantálni az oldal megfelelő működését. <br/>
                Kérlek, használj modern böngészőt a jobb felhasználói élmény érdekében.</span>
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default UnsupportedBrowser;