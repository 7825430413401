import React from 'react';
import feather from 'feather-icons';

interface Props {
  name: string;
  size?: number;
  title?: string;
  color?: string;
  strokeWidth?: number;
}

const Icon = ({ name, size, className, title, color, strokeWidth = 1.5 }: Props & React.HTMLAttributes<HTMLSpanElement>) => {
  const featherString = feather.icons[name].toSvg({
    class: className,
    width: size || 16,
    height: size || 16,
    'stroke-width': strokeWidth
  });

  return <span title={title} style={{ display: 'inline-block', lineHeight: 1, color: color }} dangerouslySetInnerHTML={{ __html: featherString }} />;
};

export default Icon;