// extracted by mini-css-extract-plugin
export var column = "footer-module--column--01b8b";
export var columns = "footer-module--columns--9b70c";
export var content = "footer-module--content--90752";
export var copyright = "footer-module--copyright--060f5";
export var footer = "footer-module--footer--954ee";
export var footerLinks = "footer-module--footer-links--cae39";
export var logo = "footer-module--logo--e67c9";
export var logoWrapper = "footer-module--logo-wrapper--8c6fc";
export var section = "footer-module--section--5befd";
export var sectionTitle = "footer-module--section-title--ce965";
export var social = "footer-module--social--01762";