import React from 'react';
import * as styles from './badge.module.scss';

interface Props {
  backgroundColor?: string;
}

const Badge = ({ children, className, backgroundColor }: Props & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={`${styles.badge} ${className || ''}`} style={{ backgroundColor: backgroundColor }}>{children}</span>
);

export default Badge;