const LocalStorageService = {
  get<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item === null ? null : JSON.parse(item);
  },

  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  },

  hasKey(key: string): boolean {
    return localStorage.getItem(key) !== null;
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },

  clear(): void {
    localStorage.clear();
  }
}

export default LocalStorageService;