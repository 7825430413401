exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout-success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-favourites-tsx": () => import("./../../../src/pages/favourites.tsx" /* webpackChunkName: "component---src-pages-favourites-tsx" */),
  "component---src-pages-giveaway-tsx": () => import("./../../../src/pages/giveaway.tsx" /* webpackChunkName: "component---src-pages-giveaway-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookie-policy-tsx": () => import("./../../../src/pages/legal/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-legal-cookie-policy-tsx" */),
  "component---src-pages-legal-imprint-tsx": () => import("./../../../src/pages/legal/imprint.tsx" /* webpackChunkName: "component---src-pages-legal-imprint-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-of-service-tsx": () => import("./../../../src/pages/legal/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-review-success-tsx": () => import("./../../../src/pages/review-success.tsx" /* webpackChunkName: "component---src-pages-review-success-tsx" */),
  "component---src-templates-collection-page-tsx": () => import("./../../../src/templates/collectionPage.tsx" /* webpackChunkName: "component---src-templates-collection-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

