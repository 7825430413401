module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"UA-160639346-1","autoStart":false,"anonymize":true,"controlCookieName":"cookie-consent","cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"","anonymize":true,"controlCookieName":"cookie-consent","cookieFlags":"secure;samesite=none"},"hotjar":{"trackingId":"1881760","snippetVersion":"6","controlCookieName":"cookie-consent"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pixwrist","short_name":"Pixwrist","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","icon":"src/images/pixwrist-manifest-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"72f49a20c61451452709b7c6055fd3e4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
