// extracted by mini-css-extract-plugin
export var cartIndicator = "cartIndicator-module--cart-indicator--a7b20";
export var cartIndicatorCount = "cartIndicator-module--cart-indicator-count--65eb5";
export var cartIndicatorLink = "cartIndicator-module--cart-indicator-link--704fb";
export var cartIndicatorText = "cartIndicator-module--cart-indicator-text--1a8d0";
export var icon = "cartIndicator-module--icon--598fb";
export var lastItemAdded = "cartIndicator-module--last-item-added--f945c";
export var lastItemAddedSuccess = "cartIndicator-module--last-item-added-success--623e1";
export var notificationIcon = "cartIndicator-module--notification-icon--d05df";
export var productImage = "cartIndicator-module--product-image--c76a9";
export var productInfo = "cartIndicator-module--product-info--f61d7";
export var productPrice = "cartIndicator-module--product-price--f3be6";
export var productTitle = "cartIndicator-module--product-title--7be6f";
export var productVariantTitle = "cartIndicator-module--product-variant-title--6700c";